import React from 'react'

import { Route, Switch, BrowserRouter, Redirect } from 'react-router-dom'

import Fallback from './components/fallback'
import GlobalFallback from './components/globalFallback'
import { isAuthenticated } from './services/authentication'
import { ToastContainer } from 'react-toastify'

import './assets/styles/app.css'
import 'react-toastify/dist/ReactToastify.css'


const Login = React.lazy(() => import('./pages/public/Login'))
const ForgotPassword = React.lazy(() => import('./pages/public/ForgotPassword'))
const ChangePassword = React.lazy(() => import('./pages/public/ChangePassword'))
const Register = React.lazy(() => import('./pages/public/Register'))
const ActivateAccount = React.lazy(() => import('./pages/public/ActivateAccount'))

const Layout = React.lazy(() => import('./pages/private/Layout'))



const PrivateRoute = ({ component: Component, ...rest }) => (
	<Route
	  	{...rest}

	  	render={props =>isAuthenticated() ? (
				<Component {...props} />
			) : (
				<Redirect to={{ pathname: "/login", state: { from: props.location } }} />
			)
	  	}
	/>
)

export default function App(props) {
	
	return (
		<>
			<GlobalFallback />
			<ToastContainer toastStyle={{ backgroundColor: "crimson" }}/>

			<BrowserRouter>
				<React.Suspense fallback={<Fallback/>}>
					<Switch>
						<Route exact path="/login" render={props => <Login {...props}/>} />
						<Route exact path="/recuperar-senha" render={props => <ForgotPassword {...props}/>} />
						<Route exact path="/nova-senha" render={props => <ChangePassword {...props}/>} />
						<Route exact path="/registro" render={props => <Register {...props}/>} />
						<Route exact path="/ativar-cadastro" render={props => <ActivateAccount {...props}/>} />

						<PrivateRoute path="/" component={props => <Layout {...props}/>} />

						<Route path="*">
							Err
						</Route>

					</Switch>
				</React.Suspense>
			</BrowserRouter>
		</>
	)
}