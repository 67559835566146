
import Spinner from 'react-spinner-material'

import { LogoWithoutCircle } from './../../config/images'
import { ProjectName } from './../../config/constants'

export default function () {

    return(
        <div className="global-fallback-routes">
            <div className='global-fallback-routes-content'>
                <div>
                    <Spinner radius={150} color={'#fff'} stroke={6} visible={true}  />
                </div>

                <img src={LogoWithoutCircle} alt={ProjectName}/>  
            </div>
        </div>
    )
}